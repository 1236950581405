import { AccordionDetails } from '@mui/material';
import React, { ReactElement } from 'react';
import { PulseAccordionDetailsProps } from './pulse-accordion-details-types';
import styles from '../../pulse-accordion.module.scss';

export const PulseAccordionDetails = (props: PulseAccordionDetailsProps): ReactElement => {
  const { children, ...restProps } = props;

  return (
    <div data-testid="pulseAccordionDetailsRoot" className={styles.pulseAccordionDetailsRoot}>
      <AccordionDetails {...restProps}>{children}</AccordionDetails>
    </div>
  );
};

export default PulseAccordionDetails;
