import { BookingEventColorType } from '../components/booking-types';
import RPBookingModel from '../models/rp-booking-model';

export const getEventColor = (eventRecord: RPBookingModel): string => {
  let eventColor = BookingEventColorType.standard;

  switch (eventRecord.bookingStatus.value) {
    case 'workCompleted':
    case 'confirmed':
      if (eventRecord.priority === 'escalated') {
        eventColor = BookingEventColorType.escalated;
      }
      if (eventRecord?.job?.isNC) {
        eventColor = BookingEventColorType.nonCharge;
      }
      if (eventRecord.ptoRequestId) {
        eventColor = BookingEventColorType.pto;
      }
      break;
    case 'tentative':
      eventColor = BookingEventColorType.standardTentative;
      if (eventRecord.priority === 'escalated') {
        eventColor = BookingEventColorType.escalatedTentative;
      }
      if (eventRecord?.job?.isNC) {
        eventColor = BookingEventColorType.nonChargeTentative;
      }
      if (eventRecord.ptoRequestId) {
        eventColor = BookingEventColorType.ptoTentative;
      }
      break;
    case 'unapproved':
      eventColor = BookingEventColorType.unApproved;
      break;
    default:
      eventColor = BookingEventColorType.standard;
  }
  return eventColor;
};

const exceptedKeys = [
  'activityid',
  'jobid',
  'singleday',
  'daysbetween',
  'bookingid',
  'roleid',
  'departmentid',
  'reportedby',
  'color',
];

export const BOOKING_HISTORIES_GRID_JQ = `{
  data: [
      .included as $included |
      .data[] as $data |
      $data |
      .relationships["user"].data as $user |
      $data.attributes as $attributes |
      $attributes.old_values as $oldValue |
      $attributes.new_values as $newValue |
      $oldValue | length as $countOldValue |
      $newValue | length as $countNewValue |
      $oldValue | (if ($countOldValue != 0) 
                      then 
                          del( . ["created_by", "updated_by", 
                          "${exceptedKeys.join(`", "`)}"])
                      else
                          $oldValue 
                      end) as $oldValueFilterKey | length as $countOldValueFilterKey |
      $newValue |  (if ($countNewValue != 0) 
                      then 
                          del( . ["created_by", "updated_by", 
                          "${exceptedKeys.join(`", "`)}"])
                      else
                          $newValue 
                      end) as $newValueFilterKey | length as $countNewValueFilterKey |
      {
          userName: (
              if $user then $included[] |
              select(.type as $type |
                  .id as $id |
                  $user |
                  select(.id == $id and.type == $type)) |
              .attributes.name
              else
                  null
              end
          ),
          resourceId: $attributes.model_id,
          updateDate: $attributes.updated_at,
          optionChanged: [ (if ($countOldValueFilterKey < $countNewValueFilterKey) then $newValueFilterKey else $oldValueFilterKey end) | to_entries[] | .key],
          oldValue: [$oldValueFilterKey |
                  to_entries[] |
                  .value 
          ],
          newValue: [$newValueFilterKey | 
                            to_entries[] | 
                            .value 
          ],
          model: $attributes.model,
          event: $attributes.event,
      }
  ] | [.[] | select(. != null)],
  page: .meta.page
}`;
